import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../atoms/Container";
import styled from "styled-components";
import {graphql} from "gatsby";

const Holder = styled.div`
  margin: 2rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    margin: 4rem 0;
  }
`;

const SliceImage = ({ slice }) => {
  const image = slice.primary.project_image.gatsbyImageData;
  const alt = slice.primary.project_image.alt;
  return (
    <Holder>
      <Container>
        <GatsbyImage alt={alt} image={image} />
      </Container>
    </Holder>
  );
};

SliceImage.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default SliceImage;

export const query = graphql`
    fragment SliceImage on PrismicCaseStudyDataBodyCaseStudyImage {
        id
        slice_type
        primary {
            project_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
            }
        }
    }
`;

