import React from "react";
import styled from "styled-components";
import Heading from "../atoms/Heading";
import {graphql, useStaticQuery} from "gatsby";
import WorkList from "./WorkList";

const Holder = styled.section`
  margin-bottom: 2rem;
`;

const MoreWork = () => {
  const data = useStaticQuery(graphql`
    query MoreWorkQuery {
        prismicHomepage {
            data {
                work {
                    case_study {
                        document {
                            ... on PrismicCaseStudy {
                                id
                                uid
                                data {
                                    client_name
                                    case_study_title {
                                        text
                                    }
                                    products {
                                        product {
                                            document {
                                                ... on PrismicProduct {
                                                    uid
                                                }
                                            }
                                        }
                                    }
                                    create_page
                                    preview_embed {
                                        video_id
                                        height
                                        width
                                        title
                                    }
                                    preview_text {
                                        richText
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  `);
  return (
    <Holder>
      <Heading title={"More Work"} />
      <WorkList projects={data.prismicHomepage.data.work}/>
    </Holder>
  );
};

export default MoreWork;

