import React from "react";
import PropTypes from "prop-types";
import Container from "../atoms/Container";
import PrismicRichText from "../atoms/PrismicRichText";
import {graphql} from "gatsby";
import styled from "styled-components";

const Holder = styled.div`
  margin: 2rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    margin: 4rem 0;
  }
`;

const Text = styled.div`
  max-width: 40rem;
`;

const SliceText = ({slice}) => {
  const text = slice.primary.project_text.richText;
  return (
    <Holder>
      <Container>
        <Text>
          <PrismicRichText render={text} />
        </Text>
      </Container>
    </Holder>
  );
};

SliceText.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default SliceText;

export const query = graphql`
    fragment SliceText on PrismicCaseStudyDataBodyTextBlock {
        id
        slice_type
        primary {
            project_text {
                richText
            }
        }
    }
`;

