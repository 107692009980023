import React from "react";
import styled from "styled-components";
import Container from "../atoms/Container";
import VimeoEmbed from "../atoms/VimeoEmbed";
import {graphql} from "gatsby";

const Holder = styled.div`
  margin: 2rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    margin: 4rem 0;
  }
`;

function SliceVideo({ slice }) {
  const {
    video_id,
    title,
    height,
    width,
  } = slice.primary.embed;

  return (
    <Holder>
      <Container>
        <VimeoEmbed
          border={false}
          title={title}
          padding={`${height/width*100}%`}
          videoId={video_id}
        />
      </Container>
    </Holder>
  );
}

export default SliceVideo;

export const query = graphql`
    fragment SliceVideo on PrismicCaseStudyDataBodyCaseStudyVimeo {
        id
        slice_type
        primary {
            embed {
                embed_url
                video_id
                title
                height
                width
            }
        }
    }
`;

