import React from "react";
import {graphql} from "gatsby";
import styled from "styled-components";
import Footer from "../components/organisms/Footer";
import MoreWork from "../components/organisms/MoreWork";
import {SliceZone} from '@prismicio/react';
import SliceImage from "../components/organisms/SliceImage";
import SliceText from "../components/organisms/SliceText";
import SliceVideo from "../components/organisms/SliceVideo";
import Container from "../components/atoms/Container";
import WorkTags from "../components/atoms/WorkTags";
import Logo from "../assets/svg/logo.inline.svg";

const Holder = styled.div`
  margin-top: 10rem;
`;

const TitleHolder = styled.div`
  margin-bottom: 5rem;
`;

const Title = styled.h1`
  max-width: 45rem;

  .client {
    white-space: nowrap;
  }

  img, svg {
    width: 0.8em;
    height: 0.8em;
    object-fit: contain;
    display: inline-block;
    margin: 0 0.5rem;

    path {
      fill: var(--white);

      &:nth-child(1),
      &:nth-child(8),
      &:nth-child(11) {
        fill: var(--green);
      }

      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(9) {
        fill: var(--red);
      }

      &:nth-child(3),
      &:nth-child(6),
      &:nth-child(10) {
        fill: var(--pink);
      }

      &:nth-child(4),
      &:nth-child(7),
      &:nth-child(12) {
        fill: var(--yellow);
      }
    }
  }
`;

const Tools = styled.div`
  margin-bottom: 2rem;
  border-top: 1px solid #5b5b5b;
  border-bottom: 1px solid #5b5b5b;
  padding: 1rem 0;

  span {
    &:after {
      content: " / ";
    }

    &:last-child:after {
      content: "";
    }
  }

  h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
  }
`;

const CaseStudy = ({data}) => {
  const slices = data.prismicCaseStudy?.data.body;
  const {case_study_title, client_logo, client_name, products, live_link} = data.prismicCaseStudy.data;

  return (
    <Holder>
      <Container>
        <TitleHolder>
          <Title>
            <span>{case_study_title.text} for</span>
            <span className="client">
            {client_name !== "Internal" && <img
              src={client_logo.url}
              alt={client_name}
            />}
              {client_name === "Internal" && <Logo />}
              {client_name === "Internal" ? 'Supermarket' : client_name}
          </span>
          </Title>
          {live_link &&
            <a href={live_link.url} target="_blank" rel="noopener noreferrer" className="button">View Live Project</a>}
        </TitleHolder>
        <WorkTags products={products} />
      </Container>

      <Tools>
        <Container>
          <h2>Built with <span>&rarr;</span></h2>
          <p>{data.prismicCaseStudy.tags.map(
            (tag, index) => {
              return (
                <span key={index}>{tag}</span>
              )
            }
          )}</p>
        </Container>
      </Tools>

      <SliceZone
        slices={slices}
        components={{
          case_study_vimeo: SliceVideo,
          case_study_image: SliceImage,
          text_block: SliceText,
        }}
      />
      <MoreWork />
      <Footer />
    </Holder>
  );
};

export default CaseStudy;

export const pageQuery = graphql`
    query PostBySlug($id: String!) {
        prismicCaseStudy(id: { eq: $id }) {
            id
            uid
            tags
            data {
                client_logo {
                    url(imgixParams: {maxWidth: 100})
                }
                case_study_title {
                    text
                }
                live_link {
                    url
                }
                client_name
                preview_embed {
                    embed_url
                    video_id
                    title
                    height
                    width
                }
                products {
                    product {
                        document {
                            ... on PrismicProduct {
                                uid
                            }
                        }
                    }
                }
                body {
                    ... on PrismicCaseStudyDataBodyCaseStudyImage { ...SliceImage }
                    ... on PrismicCaseStudyDataBodyTextBlock { ...SliceText }
                    ... on PrismicCaseStudyDataBodyCaseStudyVimeo { ...SliceVideo }
                }
            }
        }
    }
`;

